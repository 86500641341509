(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/kambi-offering/assets/javascripts/deep-link.js') >= 0) return;  svs.modules.push('/components/oddset/kambi-offering/assets/javascripts/deep-link.js');
"use strict";


const urlMapping = svs.isServer ? require('trinidad-core').core.urlMapping : svs.core.urlMapping;
let sanitizeUrlPart;
if (svs.isServer) {
  sanitizeUrlPart = require('./common/sanitize-url-part.es6');
} else {
  sanitizeUrlPart = svs.components.oddset.kambiOffering.common.sanitizeUrlPart;
}

const getUrlForBetOffer = (betOffer, offeringRes) => {
  if (!betOffer || !betOffer.eventId) {
    throw new Error('No valid betOffer');
  }
  let url = urlMapping.get('oddsetHome');
  const event = offeringRes.events.find(event => event.id === betOffer.eventId);
  event.path.forEach(path => {
    url += "/".concat(sanitizeUrlPart(path.name));
  });
  url += "/".concat(event.id);
  return url;
};
const exported = {
  getUrlForBetOffer
};
if (svs.isServer) {
  module.exports = exported;
} else {
  setGlobal('svs.components.oddset.kambiOffering', exported);
}

 })(window);