(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/kambi-offering/assets/javascripts/common/normalize.js') >= 0) return;  svs.modules.push('/components/oddset/kambi-offering/assets/javascripts/common/normalize.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

let getUrlForBetOffer;
let sanitizeUrlPart;
let formatOdds;
let urlMapping;
let constants;
let getFormattedDate;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  getFormattedDate = trinidad.components.require('lb-utils', 'helper-formatdate').api.getFormattedDate;
  constants = require('./constants.es6');
  sanitizeUrlPart = require('./sanitize-url-part.es6');
  formatOdds = trinidad.components.require('utils', 'format').api.Odds;
  urlMapping = trinidad.urlMapping;
} else {
  getFormattedDate = svs.components.lbUtils.helpers.formatDate.getFormattedDate;
  constants = svs.components.oddset.kambiOffering.common.constants;
  getUrlForBetOffer = svs.components.oddset.kambiOffering.getUrlForBetOffer;
  sanitizeUrlPart = svs.components.oddset.kambiOffering.common.sanitizeUrlPart;
  formatOdds = svs.utils.format.Odds;
  urlMapping = svs.core.urlMapping;
}
const {
  BetOfferTypeId,
  OutcomeStatus,
  SocketMessagePayloadKey
} = constants;
const getOutcomeLabel = (outcome, betOfferType) => {
  switch (betOfferType) {
    case BetOfferTypeId.Handicap:
      {
        const line = outcome.line / 1000;
        return "".concat(outcome.label, " ").concat(outcome.line > 0 ? '+' : '').concat(Math.round(line * 10) / 10);
      }
    case BetOfferTypeId.OverUnder:
      {
        const line = outcome.line / 1000;
        return "".concat(outcome.label, " ").concat(Math.round(line * 10) / 10);
      }
    case BetOfferTypeId.YesNo:
    case BetOfferTypeId.Match:
    default:
      return outcome.label;
  }
};
const shouldAddParticipant = betOfferType => {
  switch (betOfferType) {
    case BetOfferTypeId.PlayerOccurrenceLine:
      return true;
    default:
      return false;
  }
};
const getOutcomeLabelPrePack = (outcome, betOfferType) => {
  let label;
  switch (betOfferType) {
    case BetOfferTypeId.Match:
      {
        if (outcome.label === 'X') {
          label = 'Oavgjort';
        }
        label = outcome.participant;
        break;
      }
    case BetOfferTypeId.YesNo:
      label = outcome.label;
      break;
    default:
      label = getOutcomeLabel(outcome, betOfferType);
      break;
  }
  if (shouldAddParticipant(betOfferType)) {
    label = "".concat(outcome.participant, " - ").concat(outcome.label);
  }
  return label;
};
const normalizeOutcomes = (outcomesArr, betOfferType) => outcomesArr.map(outcome => {
  const isSuspended = outcome.status === OutcomeStatus.Suspended;
  return _objectSpread(_objectSpread({}, outcome), {}, {
    id: outcome.id,
    label: getOutcomeLabel(outcome, betOfferType),
    odds: !outcome.odds ? null : formatOdds(outcome.odds / 1000),
    isSuspended
  });
});

const normalizeBetOffer = (betOffer, fullApiResponse) => {
  const betOfferType = betOffer.betOfferType.id;
  const betOfferId = betOffer.id;
  return {
    id: betOfferId,
    type: betOfferType,
    outcomes: normalizeOutcomes(betOffer.outcomes, betOfferType, betOfferId),
    deepLinkPath: getUrlForBetOffer(betOffer, fullApiResponse),
    isSuspended: Boolean(betOffer.suspended),
    key: betOfferId,
    englishLabel: betOffer.criterion.englishLabel,
    label: betOffer.criterion.label
  };
};
const normalizeBetOffers = (betOffersArr, fullApiResponse) => betOffersArr.map(betOffer => normalizeBetOffer(betOffer, fullApiResponse));
const normalizeSocketMessages = messageArr => messageArr.map(message => ({
  timestamp: message.t,
  type: message.mt,
  payload: message[SocketMessagePayloadKey[message.mt]]
}));
const getFormattedPrepacksDate = event => {
  const startDate = new Date(event === null || event === void 0 ? void 0 : event.start);
  const time = startDate.toLocaleString('sv-se', {
    hour: '2-digit',
    minute: '2-digit'
  });
  const formattedDate = getFormattedDate(startDate, true, true, true);
  if (!formattedDate) {
    return;
  }
  return "".concat(formattedDate, " kl. ").concat(time);
};
const normalizePrepack = prepacksData => {
  const event = prepacksData.events[0];
  const prepackSelection = prepacksData.prePacks[0].prePackSelections[0];
  const getBetoffer = betOfferId => prepacksData.betOffers.find(betOffer => betOffer.id === betOfferId);
  const getOutcome = (outcomeId, betOffer) => betOffer.outcomes.find(outcome => outcome.id === outcomeId);
  const deepLinkPath = "".concat(event.path.reduce((acc, path) => "".concat(acc, "/").concat(sanitizeUrlPart(path.name)), "".concat(urlMapping.get('oddsetHome'))), "/").concat(event.id);
  const outcomeIds = [];
  const matchHighlights = [];
  for (const group1 of prepackSelection.combinations[0].groups) {
    for (const group2 of group1.groups) {
      for (const prePackOutcome of group2.outcomes) {
        const betOffer = getBetoffer(prePackOutcome.betOfferId);
        const outcome = getOutcome(prePackOutcome.id, betOffer);
        matchHighlights.push("".concat(getOutcomeLabelPrePack(outcome, betOffer.betOfferType.id), " - ").concat(betOffer.criterion.label));
        outcomeIds.push(outcome.id);
      }
    }
  }
  const couponParam = "coupon=combination|".concat(outcomeIds.join(','), "|0|append");
  return {
    title: event.name,
    oddsFractional: formatOdds(prepackSelection.combinations[0].odds.decimal / 1000),
    matchHighlights,
    deepLinkPath: "".concat(deepLinkPath, "?").concat(couponParam),
    startTime: getFormattedPrepacksDate(event)
  };
};
const exported = {
  normalizeBetOffers,
  normalizeBetOffer,
  normalizeSocketMessages,
  normalizePrepack
};
if (svs.isServer) {
  module.exports = exported;
} else {
  setGlobal('svs.components.oddset.kambiOffering.common.normalize', exported);
}

 })(window);