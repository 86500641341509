(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/kambi-offering/assets/javascripts/common/sanitize-url-part.js') >= 0) return;  svs.modules.push('/components/oddset/kambi-offering/assets/javascripts/common/sanitize-url-part.js');
"use strict";


const sanitizeUrlPart = part => {
  if (!['number', 'string'].includes(typeof part)) {
    return;
  }
  const charMap = {
    æ: 'ae',
    œ: 'oe',
    ß: 'ss',
    þ: 'th',
    ð: 'd',
    ø: 'o'
  };
  return String(part).toLowerCase().replace(/( @ | & | - )/g, '---') 
  .replace(/[\s/_]/g, '-') 
  .normalize('NFD') 
  .replace(/[æœßþðø]/g, char => charMap[char] || char) 
  .replace(/[^a-z0-9-]/g, ''); 
};
if (svs.isServer) {
  module.exports = sanitizeUrlPart;
} else {
  setGlobal('svs.components.oddset.kambiOffering.common.sanitizeUrlPart', sanitizeUrlPart);
}

 })(window);