(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/kambi-offering/assets/javascripts/offering.js') >= 0) return;  svs.modules.push('/components/oddset/kambi-offering/assets/javascripts/offering.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

let trinidad;
let logger;
let kambiOfferingApiTimeout;
let kambiOfferingApiUrl;
let fetchFunc;
let proxyAgent;
let constants;

if (svs.isServer) {
  var _trinidad$config, _trinidad$config$opti, _trinidad$config2;
  const ProxyAgent = require('undici').ProxyAgent;
  trinidad = require('trinidad-core').core;
  fetchFunc = require('undici').fetch;
  logger = trinidad.logger('components:oddset:kambi-offering');
  kambiOfferingApiUrl = (_trinidad$config = trinidad.config) === null || _trinidad$config === void 0 || (_trinidad$config = _trinidad$config.options) === null || _trinidad$config === void 0 || (_trinidad$config = _trinidad$config.oddsetKambi) === null || _trinidad$config === void 0 ? void 0 : _trinidad$config.kambiOfferingApiUrl;
  kambiOfferingApiTimeout = (_trinidad$config$opti = (_trinidad$config2 = trinidad.config) === null || _trinidad$config2 === void 0 || (_trinidad$config2 = _trinidad$config2.options) === null || _trinidad$config2 === void 0 || (_trinidad$config2 = _trinidad$config2.oddsetKambi) === null || _trinidad$config2 === void 0 ? void 0 : _trinidad$config2.kambiOfferingApiTimeout) !== null && _trinidad$config$opti !== void 0 ? _trinidad$config$opti : 5000;
  constants = require('./common/constants.es6');
  if (process.env.HTTP_PROXY) {
    proxyAgent = new ProxyAgent({
      uri: process.env.HTTP_PROXY
    });
  }
} else {
  var _svs$oddset, _svs$oddset$kambiOffe, _svs$oddset2;
  trinidad = svs.core;
  fetchFunc = fetch;
  logger = new svs.core.log.Logger('components:oddset:kambi-offering');
  kambiOfferingApiUrl = (_svs$oddset = svs.oddset) === null || _svs$oddset === void 0 || (_svs$oddset = _svs$oddset.kambiOfferingApiUrl) === null || _svs$oddset === void 0 || (_svs$oddset = _svs$oddset.data) === null || _svs$oddset === void 0 ? void 0 : _svs$oddset.kambiOfferingApiUrl;
  kambiOfferingApiTimeout = (_svs$oddset$kambiOffe = (_svs$oddset2 = svs.oddset) === null || _svs$oddset2 === void 0 || (_svs$oddset2 = _svs$oddset2.kambiOfferingApiUrl) === null || _svs$oddset2 === void 0 || (_svs$oddset2 = _svs$oddset2.data) === null || _svs$oddset2 === void 0 ? void 0 : _svs$oddset2.kambiOfferingApiTimeout) !== null && _svs$oddset$kambiOffe !== void 0 ? _svs$oddset$kambiOffe : 5000;
  constants = svs.components.oddset.kambiOffering.common.constants;
}
const kambiFetch = function (resource) {
  let init = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return fetchFunc(resource, _objectSpread({
    signal: AbortSignal.timeout(kambiOfferingApiTimeout),
    credentials: 'omit',
    headers: {
      Accept: 'application/json'
    }
  }, init));
};
const isNumber = value => Number.isInteger(value);

const getBetOffers = async function (eventId, betOfferTypes) {
  let optionalParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  if (!eventId || !isNumber(eventId) && !(typeof eventId === 'string')) {
    throw new Error("eventId: ".concat(eventId, " is not a correct eventId or is missing"));
  }
  if (!betOfferTypes || !Array.isArray(betOfferTypes) || !betOfferTypes.every(isNumber)) {
    throw new Error("betOfferTypes: ".concat(betOfferTypes, " is not a correct betOfferTypes or is missing"));
  }
  try {
    let offeringRes;
    const params = _objectSpread(_objectSpread({}, optionalParams), {}, {
      lang: 'sv_SE',
      type: betOfferTypes.join(',')
    });
    const requestUrl = "".concat(kambiOfferingApiUrl, "/betoffer/event/").concat(eventId, "?").concat(Object.keys(params).map(key => "".concat(key, "=").concat(params[key])).join('&'));
    const options = {
      method: 'GET'
    };
    if (svs.isServer && process.env.HTTP_PROXY) {
      offeringRes = await kambiFetch(requestUrl, _objectSpread(_objectSpread({}, options), {}, {
        dispatcher: proxyAgent
      }));
    } else {
      offeringRes = await kambiFetch(requestUrl, options);
    }
    const offeringResJson = await offeringRes.json();
    return offeringResJson;
  } catch (error) {
    logger.warn('Error while fetching kambi bet offers', JSON.stringify(error));
    throw error;
  }
};

const getBetOffer = async function (eventId, betOfferType) {
  let optionalParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return await getBetOffers(eventId, [betOfferType], optionalParams);
};

const getLiveData = async function (eventId) {
  let optionalParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!eventId || !isNumber(eventId) && !(typeof eventId === 'string')) {
    throw new Error("eventId: ".concat(eventId, " is not a correct eventId or is missing"));
  }
  try {
    let liveDataRes;
    const params = _objectSpread(_objectSpread({}, optionalParams), {}, {
      lang: 'sv_SE'
    });
    const requestUrl = "".concat(kambiOfferingApiUrl, "/event/").concat(eventId, "/livedata?").concat(Object.keys(params).map(key => "".concat(key, "=").concat(params[key])).join('&'));
    const options = {
      method: 'GET'
    };
    if (svs.isServer && process.env.HTTP_PROXY) {
      liveDataRes = await kambiFetch(requestUrl, _objectSpread(_objectSpread({}, options), {}, {
        dispatcher: proxyAgent
      }));
    } else {
      liveDataRes = await kambiFetch(requestUrl, options);
    }
    const liveDataResJson = await liveDataRes.json();
    return liveDataResJson;
  } catch (error) {
    logger.warn('Error while fetching kambi live data', JSON.stringify(error));
    throw error;
  }
};
const getGroups = async () => {
  const params = {
    lang: 'sv_SE',
    market: 'SE',
    client_id: 2,
    ncid: Date.now() 
  };
  const requestUrl = "".concat(kambiOfferingApiUrl, "/group.json?").concat(Object.keys(params).map(key => "".concat(key, "=").concat(params[key])).join('&'));
  try {
    let groupsRes;
    const options = {
      method: 'GET'
    };
    if (svs.isServer && process.env.HTTP_PROXY) {
      groupsRes = await kambiFetch(requestUrl, _objectSpread(_objectSpread({}, options), {}, {
        dispatcher: proxyAgent
      }));
    } else {
      groupsRes = await kambiFetch(requestUrl, options);
    }
    const groupsResJson = await groupsRes.json();
    return groupsResJson;
  } catch (error) {
    logger.warn('Error while fetching kambi betoffer groups', JSON.stringify(error), requestUrl);
    throw error;
  }
};
const getBetradarId = async eventId => {
  try {
    var _offeringResJson$even;
    if (!eventId) {
      logger.warn('Event id is missing while getting betradarId');
      return;
    }
    let offeringRes;
    const requestUrl = "".concat(kambiOfferingApiUrl, "/idmapping/event/").concat(eventId, "?source=KAMBI");
    const options = {
      method: 'GET'
    };
    if (svs.isServer && process.env.HTTP_PROXY) {
      offeringRes = await kambiFetch(requestUrl, _objectSpread(_objectSpread({}, options), {}, {
        dispatcher: proxyAgent
      }));
    } else {
      offeringRes = await kambiFetch(requestUrl, options);
    }
    const offeringResJson = await offeringRes.json();
    return (_offeringResJson$even = offeringResJson.eventMapping.find(mapping => mapping.externalProvider === constants.ExternalProvider.BETRADAR)) === null || _offeringResJson$even === void 0 ? void 0 : _offeringResJson$even.externalId;
  } catch (error) {
    logger.warn('Error while fetching kambi id mappings', JSON.stringify(error));
    throw error;
  }
};

const getPrepacks = async prepacksId => {
  try {
    if (!prepacksId) {
      logger.warn('prepacksId is missing while fetching Kambis prepacks API');
      return;
    }
    let offeringRes;
    const requestUrl = "".concat(kambiOfferingApiUrl, "/prepack/selection/").concat(prepacksId, "?lang=sv_SE&market=SV");
    const options = {
      method: 'GET'
    };
    if (svs.isServer && process.env.HTTP_PROXY) {
      offeringRes = await kambiFetch(requestUrl, _objectSpread(_objectSpread({}, options), {}, {
        dispatcher: proxyAgent
      }));
    } else {
      offeringRes = await kambiFetch(requestUrl, options);
    }
    const offeringResJson = await offeringRes.json();
    return offeringResJson;
  } catch (error) {
    logger.warn('Error while fetching Kambis selection prepacks API', error);
    throw error;
  }
};
const exported = {
  getBetOffer,
  getBetOffers,
  getLiveData,
  getGroups,
  getBetradarId,
  getPrepacks
};
if (svs.isServer) {
  module.exports = exported;
} else {
  setGlobal('svs.components.oddset.kambiOffering', exported);
}

 })(window);